import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const DistributionManagementSystem = () => {
  const headingbanner = {
    title: `Distribution Management System`,
    content: `A platform for inventory allocation and routing`,
  };

  const data = {
    images: ["distribution-management.webp", "distribution-management-2.webp"],
    firstAlt: "UI UX to manage, route and allocate inventory",
    firstTitle: "Manage Inventory",
    secondAlt: "Check the orders assisgned and completed by drivers",
    secondTitle: "Assigned Bins",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: ["Product Development", "UI UX Designing", "UI Development"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `Our main objective was to create a user-friendly distribution management system by designing a clean and intuitive interface that simplifies supply chain activities.`,
          `Automating the routing algorithm and order allocation was a key challenge, as we wanted to intelligently allocate orders to efficient routes based on factors like distance, traffic, and delivery time.`,
          `Additionally, we focused on enabling easy integration with third-party systems, recognizing the importance of businesses using multiple tools to manage their distribution processes.`,
          `Overall, we had to provide a comprehensive and efficient solution that simplifies supply chain activities for them.`
        ],
      },
      {
        title: "Solution",
        content: [
          `Our solution aimed to enhance the visibility of orders and their distribution by implementing real-time tracking and optimization of routes. `,
          `This will simplify business processes and decrease task completion time by streamlining the order management system.`,
          `Additionally, our solution optimises the level of effort required for different user roles, allowing for more efficient task allocation and resource utilization.`,
          `Overall, this integrated approach will significantly improve order visibility and distribution while simplifying their process and optimizing efficiency.`
        ],
      },
    ],
    image: ["distribution-management-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Manage",
    para: [
      `This interface shows the list of drivers with assigned tasks and their information. In order to provide enhanced user experience we also added a map to help drivers locate the destination.`,
    ],
    content: {
      image: ["distribution-management-projectone.webp"],
      imageAlt: 'UI Screen to manage and check order status',
      imageTitle: 'Manage Orders',
    },
   
  };

  const projectData2 = {
    title: `Allocated Bins`,
    para: [
      `We also created a small dashboard that provides the total number of the drivers, vehicles, orders and bins along with a search option and date filter to offer a comprehensive view.`,
    ],
    content: {
      image: ["distribution-management-projecttwo.webp"],
      imageAlt: 'Check allocated orders and see the direction on map',
      imageTitle: 'Allocated Orders',
    },
  };

  const projectData3 = {
    title: "Assign Bins",
    para: [
      `It shows the number of completed orders through the progress bar. This provides a clear view of the number of orders completed by the drivers. `,
    ],
    content: {
      image: [
        "distribution-management-projectthree.webp",
      ],
      imageAlt: 'UI Screen to assign orders to drivers',
      imageTitle: 'Assign Orders',
    },
  };

  const conclusionData = [
    {
      para: [
        `In conclusion, our distribution management system provides a user-friendly and efficient solution for businesses to simplify their supply chain activities.`,
        `By automating the routing algorithm and order allocation, we intelligently allocate orders to efficient routes based on various factors for optimal delivery. `,
        `Easy integration with third-party systems ensures seamless communication and collaboration, while real-time order tracking enhances visibility and streamlines the order management process.`,
        `With this comprehensive and user-centric approach, our system improves order visibility, distribution, and overall efficiency, making it an invaluable tool for them in managing their distribution processes.`
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={33} />
      </div>
    </Layout>
  );
};

export default DistributionManagementSystem;

export const Head = () => (
  <Seo title="Distribution Management System - Octet Design Studio" description="We did product development, UI UX design and UI Development for distribution management system of Patanjali, one of the top player in FMCG space." />
)